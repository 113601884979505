<template>
    <div class="wallet-component" :class="currentChain ? 'current-bsc' : 'current-heco'">
        <button
            v-if="cpd_account"
            :class="{connected: cpd_account}"
        >
            <div class="switch-chain">
                <i><img :src="hecoLogo" /> </i>
                <el-switch v-model="currentChain" active-color="#000000" inactive-color="#D3A54A" />
                <i><img :src="bscLogo" /> </i>
            </div>
            <span @click="disConnectWallet">{{cpd_account ? cpd_accountText : $t('menu.connect')}}</span>
        </button>
        <button v-else @click="connectWallet" :class="{connected: cpd_account}">{{cpd_account ? cpd_accountText : $t('menu.connect')}}</button>
        <el-dialog
            :title="'Your wallet'"
            :visible.sync="visible"
            :width="$mobile ? '370px' : '620px'"
            :before-close="handleClose"
        >
            <div class="header-wallet-dialog" :class="$mobile ? 'mobile' : ''">
                <div class="address">{{cpd_account}}</div>
                <div class="buttons">
                    <div class="button-item">
                        <a target="_blank" :href="`https://testnet.hecoinfo.com/address/${cpd_account}`">
                            <span>View on Heco</span>
                            <img src="../../assets/images/pc/link-icon.png" />
                        </a>
                    </div>
                    <div class="button-item">
                        <span>Copy Address</span>
                        <img src="../../assets/images/pc/copy-icon.png" />
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {ChainName, netWork, chainList, ChainIds, ADDRESS} from '../../config/constant'
// import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import pageMixin from "../../mixins/pageMixin"
// BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

export default {
    name: 'Header',
    mixins: [pageMixin],
    data() {
        return {
            visible: false,
            currentChain: false,
            hecoLogo: chainList[this.getChainName(1)].icon,
            bscLogo: chainList[this.getChainName(0)].icon
        }
    },
    computed: {
        cpd_account() {
            return this.$store.state.account
        },
        cpd_currentChain() {
            const v = this.$store.state.chainId
            return (ChainName[v] === 'HECO' || ChainName[v] === 'HECO_TESTNET') > 0 ? false : true
        },
        cpd_accountText() {
            const account = this.cpd_account
            if (!account) {
                return ''
            }
            const len = account.length
            const arr = account.split('')
            arr.splice(4, len - 8, '....')
            return arr.join('')
        }
    },
    watch: {
        '$store.state.chainId'(v) {
            this.checkChainId(v)
            this.currentChain = this.cpd_currentChain
            setTimeout(() => {
                this.getMncBalance(this.cpd_account, ChainName[v])
                this.getGasBalance(this.cpd_account)
            }, 100)
        },
        '$store.state.reload'() {
            setTimeout(() => {
                this.getMncBalance(this.cpd_account, ChainName[this.$store.state.chainId])
                this.getGasBalance(this.cpd_account)
            }, 100)
        },
        currentChain(v) {
            const chainName = v ? this.getChainName(0) : this.getChainName(1)
            if (this.$web3.currentProvider && ChainIds[chainName] !== this.$web3.currentProvider.chainId) {
                this.switchChain(chainName)
            }
        }
    },
    created() {
        if (this.$web3.currentProvider) {
            this.$store.commit('setAccount', this.$web3.currentProvider.selectedAddress)
            sessionStorage.setItem('meta_mask_account', this.$web3.currentProvider.selectedAddress)
            if (!this.checkChainId(this.$web3.currentProvider.chainId)) {
                this.switchChain( process.env.VUE_APP_NODE_ENV === 'production' ? 'HECO' : 'HECO_TESTNET')
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.connectWallet()
        })
    },
    methods: {
        connectWallet() {
            this.$vueweb3.connectWallet(this,{
                accountsChanged: (accounts) => {
                    const address = accounts[0]
                    const chainId = this.$web3.currentProvider.chainId
                    this.$store.commit('setChainId', chainId)
                    this.$store.commit('setAccount', address)
                    this.currentChain = this.cpd_currentChain
                    this.getMncBalance(address, ChainName[chainId])
                    this.getGasBalance(address)
                },
                chainChanged: (chainId) => {
                    console.log(chainId, 9999999999)
                    this.$store.commit('setChainId', chainId)
                }
            })
        },
        switchChain(v) {
            this.$vueweb3.wallet_addEthereumChain({
                params: [
                    netWork[v]
                ]
            }, () => {
                console.log(this.$web3.currentProvider.chainId, 'this.$web3.currentProvider.chainId')
                this.$store.commit('setChainId', this.$web3.currentProvider.chainId)
            })
        },
        getGasBalance(address) {
            console.log('getGasBalance', this.$store.state.toChain)
            this.$web3.eth.getBalance(address).then(res => {
                this.$store.commit('setFromBalance', this.$web3.utils.fromWei(res, 'ether'))
                this.$web3.eth.getGasPrice().then(gasPrice => {
                    const gasAmount = this.$bigNumber(210000)
                    const totalPrice = gasAmount.times(gasPrice).toString()
                    const minGas = this.$web3.utils.fromWei(totalPrice, 'ether')
                    this.$store.commit('setFromGasPrice', this.$web3.utils.fromWei(gasPrice, 'Gwei'))
                    this.$store.commit('setMinFromGasAmount', minGas)
                })
            })
            let toChain = this.$store.state.toChain
            const chains = Object.values(chainList)
            if (!toChain || toChain === 'undefined') {
                if (chains[0].name === ChainName[this.$store.state.chainId]) {
                    toChain = chains[1].name
                } else {
                    toChain = chains[0].name
                }
            }
            // const newProvider = new Web3(netWork[toChain].rpcUrls[0])
            this.$ajax('getBalance', {
                url: netWork[toChain].rpcUrls[0],
                method: 'post',
                data: {
                    id: new Date().getTime(),
                    method: 'eth_getBalance',
                    jsonrpc: '2.0',
                    params: [address, 'latest']
                }
            }).then(res => {
                res = this.$web3.utils.hexToNumberString(res.result)
                this.$store.commit('setToBalance', this.$web3.utils.fromWei(res, 'ether'))
                this.$ajax('getBalance', {
                    url: netWork[toChain].rpcUrls[0],
                    method: 'post',
                    data: {
                        id: new Date().getTime(),
                        method: 'eth_gasPrice',
                        jsonrpc: '2.0',
                        params: []
                    }
                }).then(gasPrice => {
                    gasPrice = this.$web3.utils.hexToNumberString(gasPrice.result)
                    const gasAmount = this.$bigNumber(210000)
                    const totalPrice = gasAmount.times(gasPrice).toString()
                    const minGas = this.$web3.utils.fromWei(totalPrice, 'ether')
                    this.$store.commit('setToGasPrice', this.$web3.utils.fromWei(gasPrice, 'Gwei'))
                    this.$store.commit('setMinToGasAmount', minGas)
                })
            })
            // newProvider.eth.getBalance(address).then(res => {
            //     console.log(res, 11111)
            //     this.$store.commit('setToBalance', this.$web3.utils.fromWei(res, 'ether'))
            //     newProvider.eth.getGasPrice().then(gasPrice => {
            //         const gasAmount = this.$bigNumber(210000)
            //         const totalPrice = gasAmount.times(gasPrice).toString()
            //         const minGas = this.$web3.utils.fromWei(totalPrice, 'ether')
            //         this.$store.commit('setToGasPrice', this.$web3.utils.fromWei(gasPrice, 'Gwei'))
            //         this.$store.commit('setMinToGasAmount', minGas)
            //     })
            // })
        },
        disConnectWallet() {
            this.visible = true
        },
        getChainName(type) {
            const prod = process.env.NODE_ENV === 'production' && process.env.VUE_APP_NODE_ENV === 'production'
            if (type) {
                return prod ? 'HECO' : 'HECO_TESTNET'
            } else {
                return prod ? 'BSC' : 'BSC_TESTNET'
            }
        },
        handleClose() {
            this.visible = false
        },
        getMncBalance(address, chainName) {
            if (!chainName || chainName === 'undefined') {
                return
            }
            const mockERC20 = this.$vueweb3.contract(this.$abi.ERC20,  ADDRESS[chainName].erc20mock).methods
            mockERC20.balanceOf(address).call().then(result => {
                const balance = this.$web3.utils.fromWei(result)
                this.$store.commit('setMncBalance', balance)
            })
        }
    }
}
</script>

<style lang="less">
.wallet-component {
    display: flex;
    align-items: center;
    &.current-bsc {
        button {
            background-color: #212121;
            span {
                color: #fff;
            }
        }
    }
    button {
        background: transparent;
        padding: 6px 12px;
        border-radius: 5px;
        color: #000;
        cursor: pointer;
        font-family: ChalkboardBold;
        font-size: 16px;
        &.connected {
            border: solid 2px #000;
            display: flex;
            align-items: center;
            .switch-chain {
                display: flex;
                padding-right: 6px;
                align-items: center;
                i {
                    width: 24px;
                    height: 24px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .el-switch {
                    margin: 0 4px;
                    .el-switch__core {
                        width: 34px!important;
                    }
                }
            }
        }
    }
}
.header-wallet-dialog {
    padding: 0 24px;
    .address {
        background-color: #fff;
        border-radius: 13px;
        font-family: ChalkboardSEBold;
        font-size: 20px;
        line-height: 46px;
        text-align: center;
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
        padding: 0 8px;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        .button-item {
            display: flex;
            margin: 0 24px;
            a {
                display: flex;
            }
            span {
                font-size: 18px;
                color: #fff;
                display: flex;
                align-items: center;
                font-family: ChalkboardSEBold;
                padding-right: 12px;
            }
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
    &.mobile {
        padding: 0 0.4rem;
        .address {
            background-color: #fff;
            border-radius: 0.4rem;
            font-family: ChalkboardSEBold;
            font-size: 0.9rem;
            line-height: 2.6rem;
            text-align: center;
            color: #000;
        }
        .buttons {
            display: flex;
            justify-content: space-between;
            padding-top: 1rem;
            .button-item {
                display: flex;
                margin: 0 1rem;
                a {
                    display: flex;
                }
                span {
                    font-size: 1rem;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    font-family: ChalkboardSEBold;
                    padding-right: 0.2rem;
                    line-height: 1rem;
                }
                img {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
    }
}
</style>
