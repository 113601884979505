<template>
    <div class="page-footer">
        <div class="footer-content">
            <a href="https://github.com/metaminers">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAiCAYAAADRcLDBAAAACXBIWXMAAAsSAAALEgHS3X78AAACt0lEQVRYha1YjXXaQAyWskA8Ap0g7gZ0gjICmaAwQc0EpBOQDaATkE4AnYBsgDdQ36Wfks/ns3020Xt5ds466Tv9HyojyczmIhL+7kWkpN1/8HxR1ZexcgcpKDazveXT1cx2ADxIvZYws3DSLU7u9CoiZxH5KyI13v37A94L4j+IyFpVX6ecfhWdewdQOXsXZnakvcEyy7EAdiQgCJuNPsWHG08kazsFQDVF+YDM3RDzlpiXtF7BPVkWgTsqDswISMM1yhtFZI9/H1X1WT5S8kh7npCOqfg4I5AdbEjXbwxERBzAV1U9M/ICwdMyF/w6lRi8y/OAPfnaHZ4rpFVIufWgvW+jR+gpG24hK7TSCL69hVpuI5knXwhB9JbLCebZjQCswyUstwzu+I5vh4SRF59g/hBTXEEF1dP1Le4oyn8nBBSJtSmUyiRveA8M4jxdxyRyfTPPDpnUYPKpTnC6vvJuQMxnWKduFCUQH7oXhKoeCPFUSgV8kzxPer6XVEfG0inOjEjuf92crxHTzBsW3sdMVoaG1Zld1A6OXMuXEdM2XgeYRTQjNApT4M/ptlQ1d6xsn2D09ssteTlggay5kg6ybPgmNl/UXffRTODrPMbZ4NASxcO71czsgoXWJAXzpwKz6ni2ekVCpsfXm/U9RTd4/owDFGn6BS14c2vtgLu8J/16B4Epyi8srahW1VBwnlW16qh+uQCCXHfXwS9JXKzWPmxgROsiB3g/AcARo18NyyYZOfJbAUZ3kSMFZOH8tLaKAURp3X9/iYA0Kh6ieo7nxWsI9lygbM5KwMsA8i5BEZBrfLJMGQUy6EpysmoICykpdQ3v1ZApsW8bpfWpb9/QhbjAJP4jmrJqStWavpUJvo2qPuUcvJdg2uCiuDp2UShG2Rfg0T+SAJT7ln0ccj45wPSSiPwD/qhKGM4qX4QAAAAASUVORK5CYII=" />
            </a>
            <a href="https://twitter.com/hellometaminers">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAACXBIWXMAAAsSAAALEgHS3X78AAAB+ElEQVRYhc2YgVHCQBBF/zIWkA4MFagVEDuACsQKhAqQCpAKiBUAFYAVSAfQgXSwzuJmCJBkN2ACf4YZJhx37+7+7e6FkBIzhwAGAEJUqw2ATyJanozCzCOuX6NjiPcrQCTawRAzBwB+Kt4KS80GgO6VIURdAQluAOShcQMQouCugk7laMqx3AKIADx6/mSBbEtsnbQdEtFH+iEzC8hE+xkDeMmCs0DGOsOJA+SViGbHD4loBeBJT+ciL1haHlkSUQzgWYHytMqCyFAfQGY7CyTSWQlQU2adAzS3CIhoq9uSGS4skNZRZ3EKaKa+EN1bIMwcFcUsyyMRM3d1ew6AAMTYm9GjQtNbKzKUYFM0mJhRDWmpENiMI0TUdwziUauojbUibyWWPld6dKNLQKSD6T/AtK0GnlwjAUiKpkuy9MBq4M01fachT6QTMEtPb/ZdaBwoCyFbO3I0dYMECiOfrg7g0cKbNMuWAcmqmHmFmSfeEsADskqFcfk+z7wCHAKIH6ZlIDwgoRo1NtolfuhJ7Dmn/PTEkQkzr/Xec2BYmT0zt3Ub1npMz6qBvR4JdbY9Zj5nHFO3UjzfDMimoafh2vravQ0QM9bwBiB3NaS4Tramk4oXdUrG7Gg9+ydJ9RrC69J3urw4eFEj0h8lXlR1J5bZy61g700Av3YuDq12azO2AAAAAElFTkSuQmCC" />
            </a>
            <a href="https://t.me/metaminersofficial">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAACXBIWXMAAAsSAAALEgHS3X78AAABiklEQVRYhe2Y3VHDMBCE9zwUEDpICaECXELogBKSClKCoQJIBYEKoAPSQVyCO1hmjRSMcbBk/JOHfI+2Rrc+n+52ZKhAMgWQYnhyM3v+FYXkjOQbx+VAcuE1mBPyAeD4cEQKADdmlick7ycSIWYAsjIj+iUj1cVJzMySKQV4dEjOQoi4CKkztJAXAPuphCjwGsC1md2FCrnqKbgak1r21szqgYNaw3+FKPWvjXPj61jOAcyHEpIDeJQIteaWtcGNMlRI4b5eqX8P3RzAbV9CFHTrvr6IEODpJSNrM3voELwkpj7Qcnwz2QOSK7dpLFGDtK2PLNyYlonZRQoJro8QIVWWzkqG0mtG6mxCFsXWRxchqc+K87lZ1XdW10Xu22nWbCQCgJzdCkBZ0LU1UfVR0tG9Hxqe7ZxAnHj/F2nXWdP0/5c6ZSSL2PpAj9PX06XflFysYp1zEVIkbsRPilxd4sb8lHy7O5JPI98EeA6+91TFqF2PiRrpUUT9okYvNCeGvB2Qz93/cPsAPgH6gl1mmrw2uAAAAABJRU5ErkJggg==" />
            </a>
            <a href="https://medium.com/@metaminers.one/about">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAACXBIWXMAAAsSAAALEgHS3X78AAABwklEQVRYhe2Yi03DMBCG/wsMUDbICGWDsgEb0A0KE1AmCEzQZALYINmAbgAbkA0OXTGVe/UjdVMDEr8UNbV89mf3HnYJSsw8B1Dq9hOoJqL3vWGZecrMb5xXlQviIzPEt1bCQAakBTDL8HP4dHXGzOIPjz8IsVGRyTFjKotfALHRnwDpyCEAlwPG7QFcaGsAd14LZp7FQsth00ZCsnLYzAP92xgIm0x7CDybSNQ2IbVDfORGNxBRB2Dt6d85U3dEg5yVmSfyqOYnT/fG0XccEABTALd2AxHVAPTKxUk73XdMENFiwK4I3J5PjQ0iENeOiXsFtudTY4OI7u0vRNRbu9KZcpFUMg4FKR3hXJvPJnU3UkCgJzOh+gDgJdU/UkEkme2cXYhoeQxEKgi0rxgtcoDoLDqz07jZoaPONUNAGhMdtWq3dyXZSbeKFLDK6idp/lUXN9NuH7zlfeqYJ1Sxg9X32TGYnnSpyrsTwrJf+UDOA5tVmtO9Vm+yLDwOWjGzb0xvMQyBeFcWGHiSei35PzxrFYGTVk6tC0+OyK2vCu4Iy5xa7izawMSuCWNKFr4tlK4/aiRs5TnlnXhzCzBuAQD4BMgFyqWA7yhSAAAAAElFTkSuQmCC" />
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>

<style lang="less">
    .page-footer {
        display: flex;
        height: 98px;
        width: 100%;
        background-color: #F7B746;
        .footer-content {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                margin: 0 16px;
            }
        }
    }
</style>
